.sidebar_mar {
  // h2 {
  //   margin-top: 40px;
  // }

  .project_btn_styles {
    @include display(space-between, flex-start);
    gap: 10px;
    margin-bottom: 15px;
    // margin-top: 20px;

    .project-select {
      // padding: 16px;
      border-color: $border_color;
      border-radius: 8px;
      outline: none;
      height: 50px;
      color: $forgot;
      font-family: inherit;
      font-size: 15px;
      font-weight: 500;
    }

    button {
      color: $white;
      background-color: $black;
      padding: 12px 24px;
      border-radius: 8px;
      border: 1px solid $black;
      font-family: inherit;
      font-size: 15px;
      font-weight: 700;
    }
  }

  .project_edit_content {
    // @include display(space-between, center);
    gap: 10px;
    padding-bottom: 25px;
    border: 2px solid $page_bg;
    margin-top: 25px;
    padding: 25px 30px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;

    .label_input_flex {
      display: flex;
      flex-direction: column;
      width: 50%;

      h4 {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 20px;
      }

      p {
        font-size: 13px;
        color: $forgot;
      }
    }
  }

  .download_btn {
    @include display(center, center);
    gap: 15px;
    background-color: $page_bg;
    padding: 40px;
    color: $forgot;
    margin-top: 30px;
    border: 1px solid #dbdbdb;
    border-radius: 14px;

    p {
      span {
        font-weight: bold;
        color: $black;
      }
    }
  }

  .your_ai_btn {
    margin: 20px 0;

    button {
      background-color: $black;
      color: $white;
      padding: 12px 24px;
      border: 1px solid $black;
      border-radius: 8px;
      font-family: inherit;
      font-size: 15px;
      font-weight: 700;
    }
  }

  .document_bg {
    background-color: $page_bg;
    border-radius: 4px;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 25px 0 10px;

    .document-option-item {
      .label_text {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
      }

      .upload-title {
        color: $forgot;
        // text-align: center;
      }

      .upload-pdf {
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .upload-buttons {
          margin-block: 20px;
          display: flex;
          gap: 15px;

          .picker-btn {
            background-color: $white;
            font-family: inherit;
            padding: 5px 10px;

            .icon {
              width: 25px;
            }
          }
        }

        .selected-file {
          .file-name {
            font-weight: 500;
            font-size: 14px;
            color: $forgot;
            margin: 10px 0 25px 0;
            background-color: $white;
            border: 1px solid $border_color;
            padding: 10px;
            border-radius: 8px;
            cursor: pointer;
          }
        }

        .action-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;

          button {
            background-color: $white;
            border: 1px solid $border_color;
            font-size: 13px;
            font-weight: 600;
            font-family: inherit;
          }
        }

        input {
          padding: 0;
          border-radius: 0;
        }
      }
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    width: 30%;
    // background: white;
    // padding: 15px;
    border-radius: 5px;
    text-align: center;

    button{
      font-family: inherit;
      font-size: 13px;
      font-weight: 600;
    }

    .label_text {
      font-weight: 500;
      font-size: 16px;
      font-family: inherit;
      line-height: 18px;
      letter-spacing: 1px;
    }

    .document_bg {
      .document-option-item {
        .upload-title {
          color: $forgot;
          text-align: center;
          margin-bottom: 30px;
        }

        .model_input {
          margin-bottom: 0;
          text-align: left;

          .label_input_flex {
            label {
              margin-bottom: 6px;
              font-size: 15px;
              font-weight: 500;
            }

            select {
              margin-top: 5px;
              padding: 10px;
              border: 1px solid $forgot;
              border-radius: 8px;
              outline: none;
              box-shadow: none;
              font-family: inherit;
              font-weight: 500;
              font-size: 15px;
              color: $forgot;
              background-color: $white;
              // margin-bottom: 10px;
              font-family: inherit;
            }
          }
        }

        .upload-pdf {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;

          .upload-title {
            color: $forgot;
          }

          .upload-buttons {
            margin-block: 20px;
            display: flex;
            gap: 15px;

            .picker-btn {
              background-color: $white;

              .icon {
                width: 25px;
              }
            }
          }

          .selected-file {
            .file-name {
              font-weight: 500;
              font-size: 14px;
              color: $forgot;
              margin: 10px 0 25px 0;
              background-color: $white;
              border: 1px solid $border_color;
              padding: 10px;
              border-radius: 8px;
              cursor: pointer;
            }
          }

          .upload-close-btn {
            position: absolute;
            top: -17px;
            right: -17px;
            background: inherit;
          }

          .action-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            button {
              background-color: $white;
              border: 1px solid $border_color;
              font-size: 13px;
              font-weight: 600;
              font-family: inherit;
            }
          }

          input {
            padding: 0;
            border-radius: 0;
          }
        }
      }
    }
  }
}
