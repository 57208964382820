.auth-success {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f6f6f6;
  color: #636363;

  .auth-header {
    width: 100%;
    padding: 15px 0;
    text-align: center;
    font-size: 24px;
    font-weight: bold;

    h1 {
      color: #4caf50;
    }
  }

  .auth-content {
    margin-top: 20px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 400px;

    h2 {
      color: #4caf50;
    }

    p {
      margin-top: 10px;
      font-size: 16px;
      color: #666;
    }
  }
}
