// Fonts (uncomment the one you want to use)
// font-family: 'Kanit', sans-serif;
// font-family: 'Merriweather', serif;
// font-family: 'Mukta', sans-serif;
// font-family: 'Open Sans', sans-serif;
// font-family: 'PT Serif', serif;
// font-family: 'Roboto', sans-serif;

// Breakpoints
$breakpoint_XXL: "max-width: 1400px";
$breakpoint_XL: "max-width: 1200px";
$breakpoint_LG: "max-width: 992px";
$breakpoint_MD: "max-width: 768px";
$breakpoint_SM: "max-width: 576px";
$breakpoint_450: "max-width: 450px";
$breakpoint_ESM: "max-width: 385px";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  // text-transform: capitalize;
  // old
  // font-family: "Kanit", sans-serif;

  // new
  font-family: "Inter", sans-serif;
}


// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox
input[type="number"] {
  -moz-appearance: textfield;
}

@mixin display($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

h1, h2, h3, h4, h5, h6 {
  // text-align: center;
}

/* General Styling */
body {
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin: 20px 0;
  letter-spacing: 1px;
}

/* Center the authorize button */
#authorize_button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #34a853;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px auto;
  text-align: center;
  display: block;
}

#authorize_button:hover {
  background-color: #2c8a46;
}

/* Signout button styling */
#signout_button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px auto;
  display: block;
}

#signout_button:hover {
  background-color: #d32f2f;
}

/* Content styling */
#content {
  margin: 40px auto;
  padding: 20px;
  max-width: 900px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  height: 150px; /* Adjust height as needed */
}

/* File list styling */
#content div {
  padding: 10px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  width: 500pxpx;
  background-color: #fafafa;
  border-radius: 4px;
  margin-bottom: 10px;
}

#content div:last-child {
  border-bottom: none;
}

#content div {
  padding: 12px; /* Slightly increased padding for better spacing */
  border-bottom: 1px solid #eee;
  font-size: 14px; /* Adjusted font size for readability */
  width: 100%; /* Changed to 100% to ensure responsiveness */
  max-width: 500px; /* Set max-width to maintain layout */
  background-color: #fafafa;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added subtle shadow for depth */
}

#content div:last-child {
  border-bottom: none;
}

#content div button {
  padding: 10px 15px; /* Adjusted padding for better button appearance */
  font-size: 12px; /* Increased font size for better readability */
  color: white;
  background-color: #ff9800;
  border: none;
  border-radius: 4px;
  margin-left: 128px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#content div button:hover {
  background-color: #e68900;
}

/* Pagination Controls */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px; /* Added padding for better button appearance */
  font-size: 14px; /* Increased font size for better readability */
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px; /* Added spacing between pagination buttons */
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}
.pagination button {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  button {
    font-size: 14px;
    padding: 8px 16px;
  }

  #content {
    padding: 15px;
    width: 90%;
    height: auto; /* Adjust height for smaller screens */
  }

  #content div {
    font-size: 10px;
  }

  #content div button {
    margin-top: 8px;
    align-self: flex-end;
  }
}

p {
  // text-align: center;
}

a {
  text-decoration: none;
}

// Input
input {
  &:focus-visible {
    outline: none;
  }
}

.coustom_container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 150px;

  @media ($breakpoint_XL) {
    padding: 0 70px;
  }

  @media ($breakpoint_LG) {
    padding: 0 30px;
  }

  @media ($breakpoint_MD) {
    padding: 0 18px;
  }

  @media ($breakpoint_SM) {
    padding: 0 15px;
  }
}

.createInput {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  outline: none;
  box-shadow: none;
  width: 100%;
  letter-spacing: 0.5px;
  font-size: 15px;
}
.createInput::placeholder{
  line-height: 18px;
  font-family: 'Inter', sans-serif;
  color: '#636363';
}

.dropdown-toggle {
  background-color: transparent;
  padding: 0;
}

.dropdown {
  position: relative;

  .dropdown-menu {
    transform: translate3d(-88px, 40px, 0px) !important;
    display: flex;
    background-color: rgba(250, 250, 250, 0.9882352941);
    padding: 10px;
    width: 130px;
    flex-direction: column;
    box-shadow: 1px 1px 6px #d0c3c3;

    .dropdown-item {
      padding: 4px 10px;

      a {
        color: #000;
      }
    }
  }
}


.picker-dialog-bg {
  z-index: 20000 !important;
}

.picker-dialog {
  z-index: 20001 !important;
}
